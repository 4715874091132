<template>
	<TnCard
		:class="['account_number_card', { account_number_card__small: small }]"
		border-radius="s"
		shadow="s"
		border
	>
		<div class="header">
			<TnIcon name="credit-card" />

			<TnHeading
				responsive
				size="s"
			>
				{{ paymentHeader }}
			</TnHeading>
		</div>

		<div class="content">
			<TnTextField
				v-model="accountNumberInput"
				data-cy="account-number"
				label="Kontonummer"
				placeholder="XXXX.XX.XXXXX"
				maxlength="13"
				pattern="[0-9]*"
				inputmode="numeric"
				:disabled="!changeAccountNumber"
			>
				<template
					v-if="!validAccountNumber && accountNumberStripped.length < 11"
					#hint
				>
					11 siffer
				</template>
				<template
					v-if="accountNumberStripped.length > 10 && !validAccountNumber"
					#warning
				>
					Kontonummer er ikke i et gyldig format!
				</template>
				<template
					v-if="missingAccountNumber"
					#error
				>
					{{ accountNumberErrorMessage }}
				</template>
				<template
					#success
					v-if="validAccountNumber"
				>
					{{ accountNumberValidMessage }}
				</template>
			</TnTextField>

			<div class="button-side">
				<TnButton
					secondary
					data-cy="change-account-number"
					iconRight="edit"
					size="xs"
					:text="changeButtonText"
					:disabled="submitting"
					@click="changeAccountNumber = !changeAccountNumber"
				/>
			</div>
		</div>
	</TnCard>
</template>

<script setup lang="ts">
import validateAccountNumber from "~/helpers/validateAccountNumber";

defineProps({
	submitting: {
		type: Boolean,
		default: false,
	},
	changeButtonText: {
		type: String,
		default: "Endre",
	},
	paymentHeader: {
		type: String,
		default: "Beløp utbetales til",
	},
	small: {
		type: Boolean,
		default: true,
	},
});

const accountNumber = defineModel("accountNumber", { type: String, default: "" });

const accountNumberErrorMessage = "Du er nødt til å oppgi et gyldig kontonummer.";
const accountNumberValidMessage = "Dette er et gyldig kontonummer.";
const changeAccountNumber = ref(false);
const missingAccountNumber = ref(false);
const accountNumberInput = ref("");

const accountNumberStripped = computed(() => {
	return accountNumberInput.value?.replaceAll(".", "");
});

const validAccountNumber = computed(() => {
	return !!(accountNumberStripped.value?.length === 11 && validateAccountNumber(accountNumberStripped.value));
});

watch(
	accountNumberInput,
	() => {
		if (accountNumberInput.value?.length === 0) {
			changeAccountNumber.value = true;
		} else if (accountNumberInput.value?.length > 0) {
			accountNumberInput.value = accountNumberInput.value.replaceAll(".", "");

			if (accountNumberInput.value?.length > 4 && accountNumberInput.value.charAt(4) !== ".") {
				accountNumberInput.value = [accountNumberInput.value.slice(0, 4), ".", accountNumberInput.value.slice(4)].join(
					"",
				);
			}

			if (accountNumberInput.value?.length > 7 && accountNumberInput.value.charAt(7) !== ".") {
				accountNumberInput.value = [accountNumberInput.value.slice(0, 7), ".", accountNumberInput.value.slice(7)].join(
					"",
				);
			}
		}

		if (!validAccountNumber.value || accountNumberInput.value?.length === 0) {
			accountNumber.value = "";
		}

		if (validAccountNumber.value) {
			changeAccountNumber.value = false;
		}
		accountNumber.value = accountNumberInput.value;
	},
	{
		immediate: true,
	},
);

watch(
	accountNumber,
	() => {
		if (accountNumber.value?.length === 0) {
			changeAccountNumber.value = true;
			missingAccountNumber.value = true;
		} else {
			accountNumberInput.value = accountNumber.value;
			missingAccountNumber.value = false;
		}
	},
	{
		immediate: true,
	},
);
</script>

<style scoped lang="scss">
.account_number_card {
	display: flex;
	flex-direction: column;
	padding: $spacing-m;
	gap: $spacing-xl;

	&__small {
		gap: $spacing-xs;
	}

	.header {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: $spacing-m;
	}

	.content {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		width: 100%;

		.button-side {
			display: flex;
			align-items: flex-end;
		}
	}
}
</style>
